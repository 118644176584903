import React, { Component, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { initWebSocket } from '../actions/stream';
import { fetchSystemStatus } from '../actions/system';
import { LastLocationProvider } from 'react-router-last-location';

// import './App.css';
import './App.scss';
import ProtectedRoute from './ProtectedRoute';
import SymbolOverview from '../views/symbolOverview/SymbolOverview';
import SearchResultContainer from '../views/marketBoard/SearchResultContainer';
import NotFoundView from '../views/errors/NotFoundView';
import MarketBoardsContainer from '../views/marketBoard/MarketBoardsContainer';
import MarketBoardSymbolSearchContainer from '../views/marketBoard/MarketBoardSymbolSearchContainer';
import MarketSummaryContainer from '../views/marketSummary/MarketSummaryContainer';
import SystemMaintenanceContainer from '../views/system/SystemMaintenanceView';
import GlobalMessageContainer from '../views/globalMessage/GlobalMessageContainer';
import NewsContainer from '../views/news/NewsContainer';
import ScansContainer from '../views/scan/ScansContainer';
import { BreadCrumb } from '../views/common/BreadCrumb';
import { HelpBar } from '../views/common/HelpBar';
import { TickerContainer } from '../views/ticker/TickerContainer';
import SubHeader from '../views/common/SubHeader';
import Logger from '../utils/logger';
import { MonexHeaderAndFooter } from './HeaderAndFooter';
import SavedScansLandingContainer from '../views/scan/SavedScansLandingContainer';
import { isMonexApp } from '../utils/common';
import { Feedback } from '../views/common/Feedback';
import { useDesktopWidth } from '../utils/hooks/useDesktopWidth';
import { useIntl } from 'react-intl';
import { setDocumentTitle } from '../utils/html';
import { MarketBoardEditContainer } from '../views/marketBoard/MarketBoardEditContainer';
import ScrollToTop from '../utils/scrollToTop';
import {
  useNewRelic,
  setApplicationVersion,
  setUserId
} from '../utils/hooks/useNewRelic';
import config from '../../package.json';
import HotlistContainer from '../views/hotlist/HotlistContainer';

const STATUS_POLL_SECONDS = 240;

const AppFunction = ({ user }) => {
  const isUsingMonexApp = isMonexApp();
  const isDesktopWidth = useDesktopWidth();
  const intl = useIntl();
  const version = config.version;
  const isUserTokenValid = user.tokenIsValid && user.token !== '';

  useNewRelic();

  useEffect(() => {
    // set the user ID and version
    setApplicationVersion(version);
    setUserId(user.id);
  }, [user, version]);

  useEffect(() => {
    // Default document title
    const title = intl.formatMessage({
      id: 'document.title.common'
    });
    setDocumentTitle(title);
  }, [intl]);

  return (
    <HashRouter>
      <LastLocationProvider>
        <ScrollToTop />
        <MonexHeaderAndFooter user={user} intl={intl} />
        {isDesktopWidth && isUserTokenValid && (
          <SubHeader dm={user.dm} attrSrcKey={user.attrSrcKey} />
        )}
        {!isUsingMonexApp && <BreadCrumb />}
        {isDesktopWidth && isUserTokenValid && <TickerContainer />}
        {isUsingMonexApp && <GlobalMessageContainer />}
        {!isDesktopWidth && !isUsingMonexApp && <HelpBar />}
        <div id="scrollAnchor" />
        {!isUsingMonexApp && <GlobalMessageContainer />}
        <Feedback />
        <Switch>
          <ProtectedRoute exact path="/" component={MarketBoardsContainer} />
          <ProtectedRoute
            exact
            path="/marketboard/:id"
            component={MarketBoardsContainer}
          />
          <ProtectedRoute
            exact
            path="/marketboard/edit/:id"
            component={MarketBoardEditContainer}
          />
          <ProtectedRoute
            exact
            path="/marketboard/edit/:id/:symbolposition"
            component={MarketBoardSymbolSearchContainer}
          />
          <ProtectedRoute
            exact
            path="/symbol/search"
            component={SearchResultContainer}
          />
          <ProtectedRoute
            exact
            path="/symbol/:symbol"
            component={SymbolOverview}
          />
          <ProtectedRoute exact path="/hotlists" component={HotlistContainer} />
          <ProtectedRoute exact path="/news" component={NewsContainer} />
          <ProtectedRoute exact path="/scan" component={ScansContainer} />
          <ProtectedRoute
            exact
            path="/scanresult"
            component={SavedScansLandingContainer}
          />
          <ProtectedRoute
            exact
            path="/scanresult/:id"
            component={SavedScansLandingContainer}
          />
          <ProtectedRoute
            exact
            path="/market"
            component={MarketSummaryContainer}
          />
          <ProtectedRoute
            exact
            path="/market"
            component={MarketSummaryContainer}
          />
          <ProtectedRoute component={NotFoundView} />
        </Switch>
      </LastLocationProvider>
    </HashRouter>
  );
};

class App extends Component {
  componentWillMount() {
    window.document.documentElement.lang = 'ja';
    this.props.actions.fetchSystemStatus();

    this.timeout = setInterval(() => {
      this.props.actions.fetchSystemStatus();
    }, STATUS_POLL_SECONDS * 1000);

    window.addEventListener('pageshow', function (event) {
      if (event.persisted) {
        Logger.info({
          errorCode: 'I0004',
          message: 'Application event persisted'
        });

        Logger.sendLogs();

        window.location.reload();
      }
    });

    Logger.info({
      errorCode: 'I0003',
      message: 'Application mounting',
      user: this.props.user.id
    });
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
    window.removeEventListener('hashchange');
  }

  render() {
    if (this.props.maintenance) {
      return <SystemMaintenanceContainer />;
    }
    return <AppFunction user={this.props.user} />;
  }

  componentDidCatch(error, info) {
    Logger.error({
      errorCode: 'E0001',
      message: 'Unknown error, application did catch',
      error: error,
      info: info
    });
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    user: state.user,
    maintenance: state.system.maintenance || false
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      initWebSocket: bindActionCreators(initWebSocket, dispatch),
      fetchSystemStatus: bindActionCreators(fetchSystemStatus, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
