import { useEffect, useState } from 'react';
import { useDesktopWidth } from '../utils/hooks/useDesktopWidth';
import { version } from '../version';
import { getVersionNumber } from '../utils/formatter';
import makeStyles from '@mui/styles/makeStyles';
import {
  fake_mount_tags_generator,
  hideGNaviHeaderInMonexApp,
  injectLocalMenu
} from '../views/common/LocalMenuInjector';

const useStyles = makeStyles({
  mobileVersion: {
    width: '100%',
    position: 'absolute',
    paddingTop: '12px',
    paddingLeft: '9px',
    backgroundColor: '#ffdd3f',
    fontSize: '11px'
  },
  desktopVersion: {
    position: 'absolute',
    marginTop: '147px !important',
    fontSize: '10px',
    left: 'calc(50% - 55px)'
  }
});

export const MonexHeaderAndFooter = ({ user, intl }) => {
  const [addMobileVersionOnce, setAddMobileVersionOnce] = useState(false);
  const [addDesktopVersionOnce, setAddDesktopVersionOnce] = useState(false);

  const isDesktopWidth = useDesktopWidth();

  const classes = useStyles();
  const versionNumber = getVersionNumber(version);

  let mobileVersion = document.createElement('div');
  mobileVersion.innerHTML = `<p>ver. P${versionNumber}</p>`;
  mobileVersion.className = classes.mobileVersion;
  let desktopVersion = document.createElement('div');
  desktopVersion.innerHTML = `<p>ver. P${versionNumber}</p>`;
  desktopVersion.className = classes.desktopVersion;

  const addVersionToFooter = (isDesktop) => {
    if (isDesktop) {
      setTimeout(() => {
        const desktopFooterElement = document.getElementById('navi-footer');
        if (!desktopFooterElement || addDesktopVersionOnce) {
          return null;
        }
        const desktopFooter =
          desktopFooterElement.getElementsByClassName('ft_inner')[0];
        if (desktopFooter) {
          desktopFooter.appendChild(desktopVersion);
          setAddDesktopVersionOnce(true);
        }
      }, 500);
    } else {
      setTimeout(() => {
        const mobileFooterElement = document.getElementById(
          'gnavi-mobile-footer'
        );
        if (!mobileFooterElement || addMobileVersionOnce) {
          return null;
        }
        const mobileFooter =
          mobileFooterElement.getElementsByClassName('footer_wrap')[0];
        if (mobileFooter) {
          mobileFooter.appendChild(mobileVersion);
          setAddMobileVersionOnce(true);
        }
      }, 500);
    }
  };

  const initializeGlobalNavigation = (
    classToShow,
    classToHide,
    scriptUrl,
    scriptId
  ) => {
    loadExternalScript(scriptId, scriptUrl);

    const elementsToHide = document.getElementsByClassName(classToHide);
    addClassToElements(elementsToHide, 'no-display');

    const elementsToShow = document.getElementsByClassName(classToShow);
    removeClassFromElements(elementsToShow, 'no-display');
  };

  const addClassToElements = (elements, className) => {
    for (let element of elements) {
      element.classList.add(className);
    }
  };

  const removeClassFromElements = (elements, className) => {
    for (let element of elements) {
      element.classList.remove(className);
    }
  };

  const loadExternalScript = (id, scriptUrl) => {
    const element = document.getElementById(id);
    if (element !== null) {
      return;
    }

    const script = document.createElement('script');
    script.id = id;
    script.src = scriptUrl;
    script.async = true;
    script.onload = () => {
      /*
        the `mnx.tags.mount_tags` function is the trigger of the hamburger menu
        the execution order of the script and the local menu rendering might cause some issue

        - if the hamburger menu is rendered after the local menu, the re-rendering of the local menu might not be triggered since the script is not part of the react

        - another issue is that the gnavi script itself is loaded asynchronously, so we might not be able to guarantee the execution order of the script and the local menu rendering

        the goal is to find a way to trigger the re-rendering of the local menu consistently
        
        after trying to debug the gnavi script, it seems that there are `two` places that call the `mnx.tags.mount_tags` function

        - function d
          this function seems to happen during the script evaluation (before the script `onload` event, at least the first time it is called)

          this function will render the hamburger menu if we have tags stored in the local storage, specifically the 'gnavi-mobile-dummyMember' key with the `-member` value
        
        - e.render
          this function is triggered by an ajax success callback, and seems to happen after the script `onload` event mostly but there is no guarantee

          this function will render the hamburger menu if we don't have the tags in the local storage
      */

      // we override the `mnx.tags.mount_tags` function to handle the case where the hamburger menu is rendered after the script `onload` event
      const originalFunc = window?.mnx?.tags?.mount_tags;
      if (originalFunc) {
        window.mnx.tags.mount_tags = fake_mount_tags_generator(
          originalFunc,
          injectLocalMenu,
          user,
          intl
        );
      }

      // this is used to handle the case where the hamburger menu is already rendered before the script `onload` event
      injectLocalMenu(user, intl);

      hideGNaviHeaderInMonexApp();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (isDesktopWidth) {
      initializeGlobalNavigation(
        'gnavi-desktop-only',
        'gnavi-mobile-only',
        'https://info.monex.co.jp/gnavi/v1/gnavi.js',
        'gnavi-desktop-js'
      );
      // To not display the version until it's confirmed by the business
      // addVersionToFooter(true);
    } else {
      initializeGlobalNavigation(
        'gnavi-mobile-only',
        'gnavi-desktop-only',
        'https://info.monex.co.jp/gnavi-mobile/v1/gnavi-mobile.js',
        'gnavi-mobile-js'
      );
      // To not display the version until it's confirmed by the business
      // addVersionToFooter(false);
    }
  }, [isDesktopWidth]);

  return null;
};
