import React from 'react';
import { createRoot } from 'react-dom/client';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { isMonexApp } from '../../utils/common';

const insightApiUrlProvider = () => {
  return `https://${process.env.REACT_APP_INSIGHT_API_DOMAIN}`;
};

// For the below links, the following rules are applied:
// 1. If there is no 'internalLink' parameter, the user
//    will always be sent to Sometaro.
// 2. If there IS an 'internalLink' parameter AND the
//    'internalOnly' parameter exists and is true, the
//    user will get a Popsicle link IF AND ONLY IF the
//    user is internal, otherwise they go to Sometaro.
// 3. If there is an 'internalLink' parameter but there
//    is no 'internalOnly' parameter, the page has been
//    released to end users and EVERYONE gets Popsicle.
const MENU_LOCATIONS = [
  {
    redirectLink: 'market',
    internalLink: '#/market',
    i18nKey: 'local.menu.marketsummary',
    internalOnly: false
  },
  {
    redirectLink: 'marketboard',
    internalLink: '#/',
    i18nKey: 'local.menu.marketboard'
  },
  {
    redirectLink: 'news',
    internalLink: '#/news',
    i18nKey: 'local.menu.news',
    internalOnly: false
  },
  {
    redirectLink: 'hotlists',
    internalLink: '#/hotlists',
    i18nKey: 'local.menu.rankings',
    internalOnly: false
  },
  {
    redirectLink: 'scan',
    internalLink: '#/scan',
    i18nKey: 'local.menu.scans',
    internalOnly: false
  }
];

export const localize = (intl, i18nKey) => {
  return intl.formatMessage({
    id: i18nKey
  });
};

export const buildPCLink = (user, target) => {
  const url = insightApiUrlProvider();

  const link =
    `${url}/auth/redirect/` +
    `${user.encryptedId}/${user.hash}/${target}?rf=${user.rf}` +
    `&dm=${user.dm}&attrSrcKey=${user.attrSrcKey}&rz=${user.rz}&mi=true`;
  return link;
};

export const hideGNaviHeaderInMonexApp = () => {
  if (isMonexApp()) {
    const mobileHeader = document.getElementById('gnavi-mobile-header');
    if (mobileHeader) {
      mobileHeader.style.display = 'none';
    }
  }
};

export const injectLocalMenu = (user, intl) => {
  if (document.getElementById('local-menu-injector')) {
    return;
  }

  if (!!document.getElementById('gnavi-mobile-menu-top-topics')) {
    const localMenuContainer = document.getElementById(
      'gnavi-mobile-menu-top-topics'
    );

    const localMenu = (
      <div id="local-menu-injector">
        <ul className="list-group list-group-global-menu">
          <li
            className="list-group-item total-menu"
            style={{ fontSize: '18px', fontWeight: 'bold' }}
          >
            {localize(intl, 'local.menu.title')}
          </li>
        </ul>
        <ul className="list-group list-group-local-menu">
          <li
            className="list-group-item menu notToggleLink search-bar"
            style={{ backgroundColor: '#C9CACA', height: 43 }}
          >
            <input
              id="insight-symbol-search-input"
              type="text"
              placeholder={localize(intl, 'local.menu.search.placeholder')}
              style={{
                paddingLeft: 5,
                marginLeft: 5,
                lineHeight: 'normal',
                position: 'relative',
                width: 'calc(100% - 48px)',
                maxWidth: 300
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  let button = document.getElementById(
                    'insight-symbol-search-button'
                  );
                  button.click();
                }
              }}
            />
            <IconButton
              id="insight-symbol-search-button"
              data-toggle="gnavi-mobile-modal"
              data-target="#gnavi-mobile-menu-modal"
              size="small"
              onClick={() => {
                let link = '#/symbol/search/?pattern=';
                let pattern = document.getElementById(
                  'insight-symbol-search-input'
                ).value;

                // Clear search bar
                document.getElementById('insight-symbol-search-input').value =
                  '';

                // Redirect
                if (pattern.length > 0) {
                  window.location = link + pattern;
                }
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </li>
          {MENU_LOCATIONS.map((loc) => (
            <li
              className="list-group-item menu notToggleLink"
              key={loc.redirectLink}
            >
              {/* eslint-disable-next-line */}
              <a
                data-toggle="gnavi-mobile-modal"
                data-target="#gnavi-mobile-menu-modal"
                onClick={() => {
                  let link;
                  // If there is no internal link, the page does not exist for anyone,
                  // so all users (including internal) go to Sometaro.  OR, If the link
                  // is internal only and the user is NOT internal, they still go to Sometaro
                  if (
                    !loc.internalLink ||
                    (loc.internalOnly && !user.internal)
                  ) {
                    link = buildPCLink(user, loc.redirectLink);
                  }
                  // At this point, either the link is marked 'internalOnly' AND the user is
                  // internal, OR the link has an 'internalLink' parameter AND the link is
                  // NOT marked as 'internalOnly', meaning ALL CUSTOMERS get Popsicle.
                  else {
                    link = loc.internalLink;
                  }
                  window.location = link;
                }}
              >
                {localize(intl, loc.i18nKey)}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );

    const root = createRoot(localMenuContainer);
    root.render(localMenu);
  }
};

export const fake_mount_tags_generator =
  (originalFunc, injectLocalMenu, user, intl) => (input) => {
    if (!originalFunc || typeof originalFunc !== 'function') {
      return;
    }
    originalFunc(input);

    if (
      injectLocalMenu &&
      typeof injectLocalMenu === 'function' &&
      typeof input === 'string' &&
      // the input might be types other than string e.g. null
      (input === '2' || input === '-member')
    ) {
      // `2` seems to be used to enable the hamburger menu when calling the `e.render` function
      // '-member' is used in the e2e tests to enable the hamburger menu
      injectLocalMenu(user, intl);
    }
  };
